@font-face {
  font-family: "Cormorant";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant";
  src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant";
  src: url("./fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

body {
  font-family: "Cormorant", "Times New Roman", Times, serif;
  background-color: #f0f8ff; /*	AliceBlue (W3C) */
}

.App {
  text-align: center;
}

#react-burger-cross-btn {
  outline: none;
}

#react-burger-menu-btn {
  outline: none;
}

.bm-burger-bars {
  height: 15% !important;
}

.bm-item:hover {
  color: #fff !important;
}

a,
a:visited {
  color: #555;
  text-decoration: none;
}

a:hover {
  color: #555;
  text-decoration: none;
}

.awhite {
  color: #fff !important;
  font-size: 24px;
  font-weight: bold;
}
